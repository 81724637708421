<template>
    <div class="row">
        <div class="col-md-12">
            <servicio-presupuesto-lista :servicio="servicio" :id="id" />
        </div>
        <!--<div class="col-md-12">
            <servicio-presupuesto />
        </div>-->
    </div>
    
</template>
<script>
import serviciopresupuestoslista from './presupuestos_lista.vue';
//import serviciopresupuesto from './presupuesto.vue';

export default ({
    props:['id','servicio'],
    setup() {
        return;
    },
    components: {
        'servicio-presupuesto-lista': serviciopresupuestoslista,
       // 'servicio-presupuesto': serviciopresupuesto,
    }
})
</script>
