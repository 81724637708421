<template>
    <div class="card">
        <div class="card-header bg-default">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Condiciones</h3>  
            <div class="card-tools">
               <!-- <button type="button" class="btn btn-success btn-xs" @click="guardar()">Guardar </button>-->
            </div>                          
        </div>
        <div class="card-body" >
            <div class="form-group">
                <div v-for="condicion in presupuestosaux.condiciones" :key="condicion.id" class="custom-control custom-checkbox">
                   <input v-bind:true-value="1" v-bind:false-value="0" class="custom-control-input" type="checkbox" @click="guardar(condicion.id,condicion.estado)" :checked="condicion.estado" :id="condicion.id" >
                    <label :for="condicion.id" class="custom-control-label"><p v-html="condicion.texto"></p></label>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { PwgsApi } from '../../../../../../services/PwgsApi';

export default ({
            props: ['id','seleccionado'],

    data() {
        return {
            presupuestosaux: '',
        }
    },
    methods: {
        async cargardatos() {
        
            this.presupuestosaux = this.seleccionado;
        },
        async guardar(id, estado) {
            //PUT  modulos/pwgsapi/index.php/presupuestos/228/modificar-presupuesto
            /*{"condiciones":{"12":1,"15":0}}*/
            var condiciones = { [id]: estado };
            var subidadatos = { descripcion: this.presupuestosaux.descripcion, condiciones: condiciones };
        //property sea id , value sea estado
            try {
                const api = new PwgsApi;
                await api.put('presupuestos/' + this.presupuestosaux.presupuesto.id_presupuesto + '/modificar-presupuesto', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
    },
    mounted() {
        this.cargardatos();
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },
                            

    }
})
</script>