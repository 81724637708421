<template>
    <div class="row">
        <div class="col">
            <button type="button" class="btn btn-default btn-flat" :onclick="datosurl" title="Descarga PDF">
                <i class="fas fa-file-pdf"></i>
            </button>
            <button type="button" class="btn btn-default btn-flat" :onclick="datosexcel" title="Descargar Excel">
                <i class="fas fa-file-excel"></i>
            </button>            
        </div>
        <div class="col">
            <div class="custom-control custom-switch custom-switch-on-success" v-if="this.$props.presupuesto.presupuesto.estado != 2">
                <input v-model="estado_aprobado" @change="desaprobar(this.$props.idfactura)" type="checkbox" class="custom-control-input" :id="'aprobado_' + this.$props.idfactura">
                <label class="custom-control-label" :for="'aprobado_' + this.$props.idfactura">Aprobado</label>
            </div>
        </div>
        <div class="col" style="max-width:100px">
            <button type="button" class="btn btn-danger btn-flat" v-if="this.$props.presupuesto.presupuesto.estado != 2" title="Eliminar" @click="eliminar(this.$props.idfactura)">
                <i class="fas fa-trash"></i>
            </button> 
            <button type="button" v-if="this.$props.presupuesto.presupuesto.estado != 2" class="btn btn-warning btn-flat" title="Anular" @click="anular(this.$props.idfactura)">
                <i class="fas fa-exclamation-circle"></i>
            </button> 
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header bg-default">
                    <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>Enviar</h3>  
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <select class="form-control input-sm" v-model="tipodeenvio">
                                <option v-for="envios in this.$props.presupuesto.envios_disponibles" :key="envios">{{envios}}</option>
                            </select>
                        </div>
                        <div class="col">
                          <!--  <v-select taggable multiple placeholder="Seleccione destinatario" :options="listacorreos" />        -->    
                            <select v-model="correoseleccionado" class="custom-select form-control-sm select2" >
                                        <option v-for="correos in this.$props.correo"  :key="correos" :value="correos">{{correos}}</option>
                                        </select>                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <textarea class="form-control" rows="3" placeholder="Introducir texto del mensaje"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button type="button" class="btn btn-block btn-sm btn-outline-primary " @click="enviarpresupuesto()">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { PwgsApi } from '../../../../../../services/PwgsApi';
export default ({
    props: ['id', 'seleccionado','presupuesto','correo','idfactura'],

    data() {
        return {
            listacorreos: [],
            datosurl: "",
            url: "",
            urlexcel: "",
            datosexcel: "",
            tipodeenvio: "",
            correoseleccionado: "",
            estado: this.$props.presupuesto.presupuesto.presupuesto_activo_factura, 
            estado_aprobado: false,        
        }
    },
    setup() {
        return;
    },
    components: {

    },
    methods: {
   
        async desaprobar(id) { 
            //PUT  modulos/pwgsapi/index.php/presupuestos/:id_factura/modificar-estado-presupuesto
            const api = new PwgsApi();
            if (this.estado == 0){ 
                this.estado = 1; this.estado_aprobado = true; 
            } else {
                this.estado = 0; this.estado_aprobado = false; 
            }
            let subidadatos = { estado: this.estado };
            try {
                await api.put('presupuestos/' + id + '/modificar-estado-presupuesto', subidadatos);
                this.$parent.cargar_presupuestos_generados();
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
        cargardatos() { 
            this.url = this.$props.presupuesto.url_descarga_pdf.slice(5);
            this.urlexcel = this.$props.presupuesto.url_descarga_excel.slice(5);
            this.datosurl = this.datosurl + this.url + "'";
            this.datosexcel = this.datosexcel + this.urlexcel + "'";
            this.estado = this.$props.presupuesto.presupuesto.presupuesto_activo_factura;
            if (this.estado == 1){ 
                this.estado_aprobado = true; 
            }else{
                this.estado_aprobado = false; 
            }

        },
        async enviarpresupuesto() {
            //PUT  modulos/pwgsapi/index.php/presupuestos/:id_factura/enviar-presupuesto
            try {
                const api = new PwgsApi();
                let subidadatos = { tipo: this.tipodeenvio.toLowerCase(), emails: this.correoseleccionado };
                await api.put('presupuestos/' + this.$props.idfactura + '/enviar-presupuesto', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
         },
         async anular(id) { 
            if (window.confirm("¿Quiere anular el presupuesto?")) {
                //PUT  modulos/pwgsapi/index.php/presupuestos/:id_factura/anular-presupuesto-generado
                const api = new PwgsApi();
                let subidadatos = { id_factura: id };
                try {
                    await api.put('presupuestos/' + id + '/anular-presupuesto-generado', subidadatos);
                    this.$parent.cargar_presupuestos_generados();
                    this.$toast.add({ severity: 'success', summary: 'Anulado', detail: 'Presupuesto Anulado', life: 2000 });
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error', life: 5000 });
                }
            }
        },
         async eliminar(id) { 

            if (window.confirm("¿Quiere eliminar el presupuesto?")) {

                const api = new PwgsApi();
                let subidadatos = { id_factura: id };
                try {
                    await api.delete('presupuestos/' + id + '/eliminar-presupuesto-generado', subidadatos);
                    this.$parent.cargar_presupuestos_generados();
                    this.$toast.add({ severity: 'success', summary: 'Eliminado', detail: 'Presupuesto Eliminado', life: 2000 });
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error', life: 5000 });
                }

            }
        }
    },
    mounted() {
        let aux = localStorage.getItem('direccion');
        this.datosurl = "location.href='https://" + aux;
        this.datosexcel = "location.href='https://" + aux;

        this.cargardatos();
    },
    watch: {
        presupuesto() {
            //this.$parent.cargar_presupuestos_generados();

        },


    }
})



</script>