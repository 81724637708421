<template>
    <div class="card">
        <div class="card-header bg-default">
            <h3 class="card-title"><i class="fas fa-address-card mr-2"></i>Información al perito</h3>  
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs" @click="guardar()" >Guardar </button>
            </div>            
        </div>
        <div class="card-body">
            <textarea class="form-control" rows="5" placeholder="Introducir información al perito del presupuesto" v-model="peritoaux.informacion_perito"></textarea>
        </div>
    </div>    
</template>
<script>

import { PwgsApi } from '../../../../../../services/PwgsApi';

export default ({
    props: ['id', 'seleccionado'],

    data() {
        return {
            peritoaux: '',

        }
    },
    methods: {
        async cargardatos() {

            this.peritoaux = this.seleccionado;
        },
        async guardar() {
            //PUT  modulos/pwgsapi/index.php/presupuestos/228/modificar-presupuesto
            var informacion_perito = { informacion_perito: this.peritoaux.informacion_perito };
            try {
                const api = new PwgsApi;
                await api.put('presupuestos/' + this.peritoaux.presupuesto.id_presupuesto + '/modificar-presupuesto', informacion_perito);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
            
        },
    },
    mounted() {
        this.cargardatos();
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },


    }
})

</script>